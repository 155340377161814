import React, { useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

import { sortObjects } from 'lib/sort-utils';

import Input from 'components/forms/Input';
import DropdownSelector from 'components/input/DropdownSelector';
import { ContainerBox, PageHeader } from 'components/Page';
import Table, { TableRow, TableCell } from 'components/Table';
import { ModelsPageFilters } from 'stores/Models';
import { LeftPanel, RightPanel, SidedPanel } from 'components/layout/Panels';
import { Spinner } from 'components/Loader';

const ModelGroupFilter = observer((props) => (
  <form>
    <div className="form-box">
      <DropdownSelector
        label="Group"
        placeholder={false}
        selected={props.store.groupFilter ? props.store.groupFilter : props.store.allGroups[0][0]}
        options={props.store.allGroups}
        onSelect={props.store.setGroupFilter}
      />
    </div>
  </form>
));

const ModelNameFilter = (props) => (
  <form className="form-box">
    <Input store={props.store.nameFilter} />
  </form>
);

const ModelsFilters = observer((props) => {
  const { name, group } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    props.store.setNameFilter(name);
  }, [name]);

  useEffect(() => {
    props.store.setGroupFilter(group);
  }, [group]);

  useEffect(() => {
    // NOTE(andreykurilin): 'name' and 'group' values from query params equal
    //    to undefined if empty whereas null is used by mobx-state-tree to
    //    represent the same state. That is why != comparison is used intentionally.
    // eslint-disable-next-line eqeqeq
    if (name != props.store.nameFilter.value || group != props.store.groupFilter) {
      let url = '/catalog/models';
      if (props.store.queryParams) {
        url += `?${props.store.queryParams}`;
      }
      navigate(url);
    }
  }, [props.store.queryParams]);

  return (
    <SidedPanel>
      <LeftPanel>
        <SidedPanel className="right-filling">
          <LeftPanel>
            <ModelGroupFilter store={props.store} />
          </LeftPanel>
          <RightPanel>
            <ModelNameFilter store={props.store} />
          </RightPanel>
        </SidedPanel>
      </LeftPanel>
    </SidedPanel>
  );
});

const ModelRow = (props) => {
  const definition = props.model.definition.obj;

  return (
    <TableRow>
      <TableCell>
        <div className="entity-type entity-icon" style={{ background: `url(${props.model.pictureUrl})` }} />
        <Link to={`/catalog/models/${props.model.fullName}`}>{props.model.fullName}</Link>
      </TableCell>
      <TableCell>{props.model.inherits.join(', ')}</TableCell>
      <TableCell>{definition.status}</TableCell>
    </TableRow>
  );
};

@inject('store')
@observer
export default class extends React.Component {
  constructor(props) {
    super(props);
    this.filtersStore = ModelsPageFilters.create({});
    this.filtersStore.setModelsStore(this.props.store.Models);
    this.tableHeaders = [
      { title: 'Name', value: 'fullName' },
      { title: 'Inherits', value: 'inherits' },
      { value: 'status' },
    ];
    // NOTE(andreykurilin): no need to list more fields, since fullName should
    // always give accurate result
    this.defaultSortFieldsOrder = ['fullName'];
  }

  componentDidMount() {
    if (!this.props.store.Models.loaded && !this.props.store.Models.loading) {
      this.props.store.Models.fetch();
    }
  }

  render() {
    if (!this.props.store.Models.loaded) {
      return <Spinner />;
    }
    const sortedModels = [...this.filtersStore.items];
    sortedModels.sort((modelA, modelB) =>
      sortObjects(
        modelA,
        modelB,
        this.filtersStore.sortByField,
        this.defaultSortFieldsOrder,
        this.filtersStore.reverseOrder
      )
    );

    return (
      <ContainerBox>
        <PageHeader title="Models" instanceLink />
        <ModelsFilters store={this.filtersStore} />
        <Table headers={this.tableHeaders} onSortChange={this.filtersStore.sortBy}>
          {sortedModels.map((m) => (
            <ModelRow model={m} key={m.fullName} />
          ))}
        </Table>
      </ContainerBox>
    );
  }
}
