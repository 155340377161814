import InputStore from 'components/forms/Input/InputS';

export default InputStore.named('DownloadInput').views((self) => ({
  get finalInputClassName() {
    if (self.disabled) {
      return 'loading';
    }
    if (self.error) {
      return 'invalid';
    }
    return '';
  },
}));
