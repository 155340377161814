import React from 'react';
import { observer } from 'mobx-react';

import { NewTerminalSession } from 'components/terminal/Xterm';
import CancelScriptRun from 'pages/Instances/RecordView/TopBarControls/CancelScriptRun';
import EditButton from 'pages/Instances/RecordView/TopBarControls/EditButton';
import DeleteButton from 'pages/Instances/RecordView/TopBarControls/DeleteButton';

import Store from 'stores/Store';

export default observer((props) => (
  <>
    {NewTerminalSession.suites(props.record) && (
      <NewTerminalSession instance={props.instance} record={props.record} sessionManager={Store.XTermSessionsManager} />
    )}
    <CancelScriptRun record={props.record} />
    <EditButton record={props.record} />
    <DeleteButton record={props.record} />
  </>
));
