import React, { useEffect, useState } from 'react';

import { PageHeader, ContainerBox } from 'components/Page';
import { observer, inject } from 'mobx-react';

import { PieChart } from 'components/charts/pie/PieChart';
import { PieModel } from 'components/charts/pie/PieChartS';
import { BarChart } from 'components/charts/bars/BarChart';
import { BarModel } from 'components/charts/bars/BarChartS';

import {
  VerTableStore,
  ColumnRecordLink,
  ColumnText,
  ColumnTimestamp,
  ColumnReferences,
  ColumnModel,
} from 'components/table/TableS';
import { Table } from 'components/table/Table';
import { SidedPanel, LeftPanel, RightPanel } from 'components/layout/Panels.jsx';

const ContainerItem = (props) => (
  <div className="container-item">
    <h4>{props.title}</h4>
    {props.children}
  </div>
);

const SystemsPie = inject('store')(
  observer((props) => {
    const inherits = "(inherits('std::host/Container:1') OR inherits('std::host/Host:1'))";
    const status = "std::types/Statusable:1.status != 'deleted'";

    const [store] = useState(
      PieModel.create({
        label: 'SYSTEMS',
        query: `${inherits} AND ${status}`,
        fieldInCircle: '@model',
        fieldOutCircle: 'std::types/Statusable:1.status',
      })
    );

    useEffect(() => {
      store.setUp({
        transport: props.store.TransportLayer,
        labelModifier: (value) => {
          return value.slice(value.lastIndexOf('/') + 1, value.lastIndexOf(':'));
        },
      });
    }, []);

    return <PieChart store={store} />;
  })
);

const AgentsPie = inject('store')(
  observer((props) => {
    const inherits = "inherits('std::system/App:1')";
    const status = "std::types/Statusable:1.status != 'deleted'";

    const [store] = useState(
      PieModel.create({
        label: 'AGENTS',
        query: `${inherits} AND ${status}`,
        fieldInCircle: 'std::system/App:1.applicationType',
        fieldOutCircle: 'std::types/Statusable:1.status',
      })
    );

    store.setUp({
      transport: props.store.TransportLayer,
      labelModifier: (value) => {
        return value.slice(value.lastIndexOf('::') + 2, value.lastIndexOf(':'));
      },
    });

    return <PieChart store={store} />;
  })
);

const ApiActivityChart = inject('store')(
  observer((props) => {
    const [store] = useState(
      BarModel.create({
        leftLabel: 'API Requests',
        rightLabel: 'Activity',
      })
    );

    store.setUp({
      transport: props.store.TransportLayer,
    });

    return <BarChart store={store} />;
  })
);

const Activities = inject(
  'store',
  'profile',
  'instance'
)(
  observer((props) => {
    const query =
      "inherits('std::types/Auditable:1') " +
      "AND isNotSet('std::types/Versionable:1.deletedAt') " +
      `AND std::types/Auditable:1.who IN id('${props.profile.user.email}')`;

    const [store] = useState(
      VerTableStore.create({
        paginationDefaultRowsPerPage: 10,
        paginationRowsPerPageOptions: [10, 25, 50, 100],
        selectable: false,
        query: query,
        columns: [
          ColumnModel.create(),
          ColumnRecordLink.create({ name: 'Activity', key: 'std::types/Root:1.id' }),
          ColumnText.create({ name: 'Description', key: 'std::types/Auditable:1.message', opts: { grow: 3 } }),
          ColumnReferences.create({ name: 'Affected', keys: ['std::types/Auditable:1.what'] }),
          ColumnReferences.create({ name: 'Related To', keys: ['std::types/Auditable:1.relatedTo'] }),
          ColumnReferences.create({ name: 'Agent', keys: ['std::types/Auditable:1.executor'] }),
          ColumnTimestamp.create({
            name: 'Updated At',
            key: 'std::types/Versionable:1.updatedAt',
          }),
        ],
      })
    );

    useEffect(() => {
      store.setUp({ instance: props.instance, transport: props.store.TransportLayer });
    }, []);

    return (
      <>
        <ContainerItem>
          <SidedPanel>
            <LeftPanel>
              <ApiActivityChart />
            </LeftPanel>
            <RightPanel>
              <SidedPanel>
                <LeftPanel>
                  <SystemsPie />
                </LeftPanel>
                <RightPanel>
                  <AgentsPie />
                </RightPanel>
              </SidedPanel>
            </RightPanel>
          </SidedPanel>
        </ContainerItem>
        <ContainerItem title="Your Activities">
          <Table store={store} />
        </ContainerItem>
      </>
    );
  })
);

export default () => (
  <ContainerBox>
    <PageHeader title="Dashboard" documentTitle="Dashboard" />
    <Activities />
  </ContainerBox>
);
