import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { withNavigate } from 'lib/router-helper';

import Input from 'components/forms/Input';
import PageLayout from 'pages/Auth/components/PageLayout';
import { SignUpForm, LoginForm } from 'stores/Auth';

@withNavigate
@inject('store')
@observer
export default class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.form = SignUpForm.create({});
  }

  onSuccess = () => {
    const loginForm = LoginForm.create({
      email: this.form.email.value,
      organization: this.form.organization.value,
      password: this.form.password.value,
    });
    loginForm.setUpdateURLPath(this.props.navigate);
    loginForm.login(this.props.store.Profile.setToken);
  };

  render() {
    return (
      <PageLayout title="Create Account" createAccountLink={false}>
        <form
          className="form-box"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Input key="organizationName" store={this.form.organizationName} />
          <Input key="organizationDisplayName" store={this.form.organizationDisplayName} />
          <Input key="firstName" store={this.form.firstName} />
          <Input key="lastName" store={this.form.lastName} />
          <Input key="email" store={this.form.email} />
          <Input key="password" store={this.form.password} />
          <div className="message-placeholder text-danger">{this.form.error}</div>
          <button
            type="button"
            className="btn-primary btn-block"
            onClick={() => this.form.signup(this.onSuccess)}
            disabled={!this.form.filled && this.form.creating}
          >
            Create
          </button>
        </form>
        <div className="login-link-container">
          <span>Already have account?</span>
          <Link to="/">Sign In</Link>
        </div>
      </PageLayout>
    );
  }
}
