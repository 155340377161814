import React from 'react';
import { observer, inject } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import { withLocation } from 'lib/router-helper';

import Checkbox from 'components/Checkbox';
import FormItem from 'components/forms/FormItem';
import Input from 'components/forms/Input';
import TextEditor, { SimpleTextViewer, Syntaxes } from 'components/forms/TextEditor';
import { Spinner } from 'components/Loader';
import { InstanceLink, InstanceNavItemOverrider } from 'components/Nav';
import RadioFiledSet from 'components/Radio';
import DropdownSelector from 'components/input/DropdownSelector';
import { ContainerBox, PageHeader } from 'components/Page';
import { TabsBar, TabSelector } from 'components/TabsBar';

import { SidedPanel, LeftPanel, RightPanel } from 'components/layout/Panels';

import NotFound from 'pages/NotFound';

import HostRegisterStore, { AuthMethods, KeyTypes, RegistrationMethods } from 'stores/Instances/HostRegister';

import * as queryString from 'lib/query-string';

const ButtonGroup = inject(
  'notifications',
  'instance'
)(
  observer((props) => {
    const navigate = useNavigate();
    const onSuccessRegister = (hostID) => {
      props.notifications.success('Host is successfully registered.');
      navigate(`/i/${props.instance.name}/records/${hostID}`);
    };

    const cancel = () => {
      let url = `/i/${props.instance.name}`;
      if (props.store.applicationsNoSelect) {
        url = `${url}/agents/${props.store.applicationID}`;
      } else {
        url = `${url}/hosts`;
      }
      navigate(url);
    };

    const submit = () => {
      props.store.registerHost(onSuccessRegister);
    };

    return (
      <div className="form-box">
        <FormItem>
          {props.store.registrationMethod === RegistrationMethods.manual && (
            <button type="button" className="btn btn-primary" disabled={!props.store.isReady()} onClick={submit}>
              Create
            </button>
          )}
          <button type="button" className="btn btn-default" onClick={cancel}>
            Cancel
          </button>
        </FormItem>
      </div>
    );
  })
);

const RegistrationMethodSelector = observer((props) => {
  if (!props.store.applicationID) {
    return null;
  }
  const methods = [
    { value: RegistrationMethods.auto, label: 'Auto-registration' },
    { value: RegistrationMethods.manual, label: 'Manual registration' },
  ];

  return (
    <div className="form-box">
      {!props.store.registrationMethod && <h4>Registration method</h4>}
      <RadioFiledSet
        items={methods}
        selected={props.store.registrationMethod}
        onSelect={props.store.setRegistrationMethod}
        horizontalView
      />
    </div>
  );
});

const AuthenticationSetup = observer((props) => {
  if (props.store.authMethod === AuthMethods.creds) {
    return (
      <div className="form-box form-box-line-stretch">
        <Input key="username" store={props.store.username} />
        <Input key="password" store={props.store.password} />
      </div>
    );
  }
  return (
    <>
      <div className="form-box">
        <Input key="username" store={props.store.username} />
      </div>
      <div className="form-box">
        <DropdownSelector
          label="Key Type"
          options={KeyTypes}
          onSelect={props.store.setKeyType}
          selected={props.store.keyType}
        />
      </div>
      <div className="form-box">
        <TextEditor store={this.store.privateKey} />
      </div>
      <div className="form-box">
        <Input key="passphrase" store={props.store.passphrase} />
      </div>
    </>
  );
});

const AutoRegistration = observer((props) => (
  <>
    <h4>Run commands below on target host to auto register it</h4>

    <div className="form-box">
      <Input key="description" store={props.store.description} />
    </div>
    <div className="form-box">
      <Input key="labels" store={props.store.labels} />
    </div>

    <TabsBar>
      {['wget', 'curl'].map((tool) => (
        <TabSelector
          key={tool}
          title={tool.toUpperCase()}
          active={props.store.downloader === tool}
          link="#"
          onClick={(e) => props.store.setDownloader(tool, e)}
        />
      ))}
    </TabsBar>
    <div className="form-box">
      <fieldset className="fieldset">
        <Checkbox label="Compress to one-liner" checked={props.store.oneLiner} onChange={props.store.toggleOneLiner} />
      </fieldset>
    </div>
    <div className="form-box">
      <SimpleTextViewer
        key={props.store.autoRegistrationCommand}
        syntax={Syntaxes.bash}
        value={props.store.autoRegistrationCommand}
      />
    </div>
  </>
));

const AutoRegistrationInstruction = () => (
  <div className="host-autoregistration-instruction">
    <h4>Script is going to:</h4>
    <ol>
      <li>Auto discover IP address, host name and active user name</li>
      <li>Request agent to add new host</li>
      <li>Install received public key to ~/.ssh/authorized_keys</li>
    </ol>
    <i>
      This is the recommended and most secure way to register host. Every host has unique key, managed by agent and is
      never send over network nor accessible by users.
    </i>
  </div>
);

const ManualRegistration = observer((props) => {
  return (
    <>
      <div className="form-box form-box-line-stretch">
        <Input key="hostname" store={props.store.hostName} />
        <Input key="ip" store={props.store.hostIP} />
      </div>
      <div className="form-box">
        <Input key="description" store={props.store.description} />
      </div>
      <div className="form-box">
        <Input key="labels" store={props.store.labels} />
      </div>

      <h4>Authentication method</h4>
      <div className="form-box">
        <RadioFiledSet
          items={Object.values(AuthMethods)}
          selected={props.store.authMethodName}
          onSelect={props.store.setAuthMethod}
          horizontalView
        />
      </div>
      <AuthenticationSetup store={props.store} />
      <div className="message-placeholder text-danger">{props.store.nameError}</div>
    </>
  );
});

@withLocation
@inject('instance')
@observer
export default class extends React.Component {
  constructor(props) {
    super(props);
    this.store = HostRegisterStore.create({ applicationID: props.applicationID || null });
    this.store.linkInstanceStore(this.props.instance);
  }

  componentDidMount() {
    if (!this.props.instance.Applications.loading) {
      this.props.instance.Applications.fetch();
    }
    const query = queryString.parse(this.props.location.search);
    if (query.applicationID && this.store.applicationID === null) {
      this.store.setApplicationID(query.applicationID);
    }
  }

  render() {
    if (!this.props.instance.Applications.loaded) {
      return <Spinner />;
    }

    let title;
    let titleURL;
    let subTitles;
    const documentTitle = 'Register new host';
    if (this.store.applicationsNoSelect) {
      const app = this.props.instance.Applications.getById(this.store.applicationID);
      if (!app) {
        return <NotFound message={`Agent with ID=${this.store.applicationID} does not exist.`} />;
      }

      title = 'Agents';
      titleURL = '/agents';
      subTitles = [<InstanceLink to={`/agents/${app.id}`}>{app.name}</InstanceLink>, documentTitle];
    } else {
      title = 'Hosts';
      titleURL = '/hosts';
      subTitles = ['Register new'];
    }

    return (
      <>
        {this.store.applicationsNoSelect && <InstanceNavItemOverrider baseUrl="agents" />}

        <ContainerBox>
          <PageHeader to={titleURL} title={title} subTitle={subTitles} documentTitle={documentTitle} instanceLink />
          <SidedPanel key="control">
            <LeftPanel>
              <form>
                <div className="form-box">
                  <DropdownSelector
                    label="Agent"
                    options={this.store.applicationOptions}
                    onSelect={(value) => this.store.setApplicationID(value)}
                    selected={this.store.applicationID}
                    placeholder="-- select an agent to connect the new host --"
                  />
                </div>
                <RegistrationMethodSelector store={this.store} />
              </form>
            </LeftPanel>
          </SidedPanel>
          <SidedPanel key="main">
            <LeftPanel>
              <form>
                {this.store.applicationID && this.store.registrationMethod === RegistrationMethods.auto && (
                  <AutoRegistration store={this.store} instance={this.props.instance} />
                )}
                {this.store.applicationID && this.store.registrationMethod === RegistrationMethods.manual && (
                  <ManualRegistration store={this.store} />
                )}
              </form>
            </LeftPanel>
            <RightPanel>
              {this.store.applicationID && this.store.registrationMethod === RegistrationMethods.auto && (
                <AutoRegistrationInstruction />
              )}
            </RightPanel>
          </SidedPanel>
          <SidedPanel key="footer">
            <LeftPanel>
              <form>
                <ButtonGroup store={this.store} />
              </form>
            </LeftPanel>
          </SidedPanel>
        </ContainerBox>
      </>
    );
  }
}
