import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { inject, observer } from 'mobx-react';

import Nav from 'components/Nav';
import Notifications from 'components/Notifications';
import { TerminalsModal } from 'components/terminal/Modal';

const PageBody = inject(
  'settings',
  'store'
)(
  observer((props) => {
    if (props.settings.theme.dark) {
      document.body.className = 'dark-body';
    } else {
      document.body.className = 'light-body';
    }
    return (
      <>
        {props.store.XTermSessionsManager.showTerms && (
          <TerminalsModal onClose={props.store.XTermSessionsManager.hideTerminals} />
        )}
        <div className={`grid ${props.settings.theme.pinnedSidebar ? 'pinned' : 'unpinned'}`}>
          <Notifications />
          <Nav />
          {props.children}
        </div>
      </>
    );
  })
);

export default PageBody;

@inject('store')
@observer
export class PageHeader extends React.Component {
  constructor(props) {
    super(props);
    this.oldTitle = undefined;
  }

  componentDidMount() {
    this.oldTitle = document.title;
    document.title = `ITLook - ${this.props.documentTitle || this.props.title}`;
  }

  componentDidUpdate() {
    document.title = `ITLook - ${this.props.documentTitle || this.props.title}`;
  }

  componentWillUnmount() {
    if (this.oldTitle !== undefined) {
      document.title = this.oldTitle;
    }
  }

  render() {
    let title;
    if (this.props.to) {
      let linkPath = this.props.to;
      if (this.props.instanceLink) {
        if (this.props.store.Instances.current === null) {
          linkPath = '/';
        } else {
          linkPath = this.props.to.startsWith('/') ? linkPath.slice(1) : linkPath;
          linkPath = `/i/${this.props.store.Instances.current}/${linkPath}`;
        }
      }
      title = <Link to={linkPath}>{this.props.title}</Link>;
    } else {
      title = this.props.title;
    }

    let subTitles = this.props.subTitle || [];
    if (subTitles && !Array.isArray(subTitles)) {
      subTitles = [subTitles];
    }

    const note = this.props.note && <code>{this.props.note}</code>;

    return (
      <div className="page-headline">
        <div className="title">
          <span>
            {title}
            {!subTitles && note}
          </span>
          {subTitles.map((subTitle, index) => (
            <span key={`subTitle-${index}`}>
              {subTitle}
              {index + 1 === subTitles.length && note}
            </span>
          ))}
        </div>
        {this.props.children}
      </div>
    );
  }
}

PageHeader.propType = {
  title: PropTypes.string.isRequired,
  documentTitle: PropTypes.string,
  subTitle: PropTypes.oneOf([PropTypes.string, PropTypes.array[PropTypes.string]]),
  note: PropTypes.string,
  to: PropTypes.string,
};

export const Container = (props) => <div className="container-home">{props.children}</div>;
export const ContainerBox = (props) => (
  <div className="container-box" {...props}>
    {props.children}
  </div>
);

export const TwoPanels = (props) => (
  <div className="two-panels">
    <div className="left">{props.left}</div>
    <div className="right">{props.right}</div>
  </div>
);
