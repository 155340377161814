import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

import FormItem from 'components/forms/FormItem';
import { SimpleTextViewer, Syntaxes } from 'components/forms/TextEditor';
import { Spinner } from 'components/Loader';
import { formatHostInfo } from 'lib/hosts';

import {
  VerTableStore,
  ColumnRecordLink,
  ColumnCustom,
  ColumnLabels,
  ColumnModel,
  ColumnTimestamp,
  ColumnInventoryRecordStatus,
  loadRecodsWithStatusStats,
} from 'components/table/TableS';
import { Table } from 'components/table/Table';

const ScriptRunner = inject(
  'instance',
  'store'
)(
  observer((props) => {
    const navigate = useNavigate();

    const [tableStore] = useState(
      VerTableStore.create({
        paginationDefaultRowsPerPage: 5,
        paginationRowsPerPageOptions: [5, 10, 15, 50, 100],
        selectable: true,
        query:
          "(inherits('std::host/Container:1') OR inherits('std::host/Host:1')) AND std::types/Statusable:1.status == 'ok'",
        columns: [
          ColumnModel.create({}),
          ColumnInventoryRecordStatus.create({ name: 'Status', opts: { width: '100px' } }),
          ColumnRecordLink.create({ name: 'Name', key: 'std::types/Root:1.id' }),
          ColumnCustom.create({ name: 'Access' }).setRender((row) => {
            const hostData = row.data.get('std::host/Host:1');
            const containerData = row.data.get('std::host/Container:1');
            const { userAtIP } = formatHostInfo(hostData, containerData);
            return userAtIP;
          }),
          ColumnLabels.create({ name: 'Labels', key: 'std::types/Root:1.labels' }),
          ColumnTimestamp.create({ name: 'Updated', key: 'std::types/Versionable:1.updatedAt' }),
        ],
      })
    );

    useEffect(() => {
      loadRecodsWithStatusStats(tableStore);
      tableStore.setUp({ instance: props.instance, transport: props.store.TransportLayer });
    }, []);

    const [showForm, setShowForm] = useState(false);
    const [requestIsGone, setRequestIsGone] = useState(false);

    if (!showForm) {
      return (
        <form>
          <button type="button" className="btn btn-primary" onClick={() => setShowForm(true)}>
            Run Script
          </button>
        </form>
      );
    }

    const onSubmit = async () => {
      tableStore.selectedRows.forEach((host) => {
        const socket = props.instance.getSocket();
        socket.afterOpen(async () => {
          const body = {
            script_id: props.record.root_1.id,
            script_version: props.record.versionable_1.version,
            code_app_id: props.record.root_1.app,
            host_id: host.uuid,
            script_args: {},
          };
          const app = socket.ensureAppConnection(host.data.get('std::types/Root:1').get('app'));
          const scriptSocket = await app.createSocket('script');
          if (!requestIsGone) {
            setRequestIsGone(true);
            const response = await scriptSocket.send_socket_rpc('run', body);
            const recordId = response.script_run['std::types/Root:1'].id;
            if (tableStore.selectedRows.size === 1) {
              navigate(`/i/${props.instance.name}/records/${recordId}`);
            } else {
              // timeout is needed as search might not return results at the right moment
              setTimeout(
                () => navigate(`/i/${props.instance.name}/records/${props.record.root_1.id}/script-runs`),
                1000
              );
            }
          }
        });
      });
    };

    return (
      <form>
        <h3>Script run parameters</h3>
        <span>Select host(s) to run the script.</span>

        <Table store={tableStore} />

        <div className="form-box">
          <FormItem>
            <button
              type="button"
              className="btn btn-success"
              onClick={onSubmit}
              disabled={!tableStore.selectedRows.size || requestIsGone}
            >
              Run it
            </button>
            <button
              type="button"
              className="btn btn-default"
              onClick={() => setShowForm(false)}
              disabled={requestIsGone}
            >
              Cancel
            </button>
          </FormItem>
        </div>
      </form>
    );
  })
);

const ScriptTab = inject('instance')(
  observer((props) => {
    const itIsScriptRunView = Boolean(props.record.script_run_1);
    let scriptRecord;
    if (itIsScriptRunView) {
      scriptRecord = props.record.script_run_1.scriptObj;
    } else {
      scriptRecord = props.record;
    }

    if (!scriptRecord.loaded) {
      return <Spinner />;
    }

    return (
      <div className="form-box">
        <SimpleTextViewer
          label={`Code [syntax: ${scriptRecord.script_1.syntax}]`}
          syntax={Syntaxes.hasOwnProperty(scriptRecord.script_1.syntax) ? scriptRecord.script_1.syntax : Syntaxes.plain}
          value={scriptRecord.script_1.code.join('\n')}
          filename={scriptRecord.fs_node_1.name}
        />
        {!itIsScriptRunView && <ScriptRunner record={scriptRecord} />}
      </div>
    );
  })
);

ScriptTab.suites = (record) => record.script_1 || record.script_run_1;

export default ScriptTab;
