import React, { useEffect } from 'react';
import { observer, Provider } from 'mobx-react';
import { BrowserRouter, Route, Routes, useParams, Navigate } from 'react-router-dom';

import store from 'stores/Store';

// common stuff
import { PopoversRootContainer } from 'components/Popover';
import NotFound from 'pages/NotFound';
import { Spinner } from 'components/Loader';
import PageBody from 'components/Page';
import { InstanceStates, InstanceReadinessStages, HOST_APP_TYPE } from 'Constants';

// Auth
import Login from 'pages/Auth/Login';
import OAuth2 from 'pages/Auth/OAuth';
import { PasswordRecoveryRequest, PasswordRecoverySetPassword } from 'pages/Auth/PasswordRecovery';
import SignUp from 'pages/Auth/SignUp';
import RedirectToLogin from 'components/RedirectToLogin';

// Dev stuff
import ExpPage from 'experiments/ExpFullPage';

// Global, instance unrelated
import Welcome from 'pages/Welcome';
import ModelPage from 'pages/Models/Model';
import ModelsList from 'pages/Models/ModelsList';
import OrganizationViews from 'pages/Organization/Organization';
import UserCreate from 'pages/Organization/Users/UserCreate';
import UserInvite from 'pages/Organization/Users/UserInvite';
import RightSideBarWithUserInfo from 'pages/Organization/Users/RightSideBarWithUserInfo';
import UserProfile from 'pages/Profile';

// instance related
import InstanceDashboard from 'pages/Instances/Dashboard';
import InstanceNotReady from 'pages/Instances/InstanceNotReady';
import RecordView from 'pages/Instances/RecordView/RecordView';
import HostsSearchPage from 'pages/Instances/Hosts/HostsList';
import NewHostPage from 'pages/Instances/Hosts/RegisterNewHost';
import ContainersSearchPage from 'pages/Instances/Containers/ContainersList';

import ScriptsList from 'pages/Instances/Scripts/ScriptsList';
import { ScriptCreatePage, ScriptEditPage } from 'pages/Instances/Scripts/ScriptEditor';

import Ansible from 'pages/Instances/Ansible';

import { SearchResultsPage } from 'pages/Instances/GlobalSearch/SearchResults';

import UsersSearchPage from 'pages/Instances/Users/UsersList';

import AgentNew from 'pages/Instances/Agents/AgentNew';
import AgentsList from 'pages/Instances/Agents/AgentsList';

import InstanceSettings from 'pages/Instances/Instance';
import Api from 'pages/Instances/Api/Api';

const isDevEnv = process.env.NODE_ENV === 'development' || !process.env.NODE_ENV;

const AnonymousView = () => (
  <React.StrictMode>
    <Routes>
      <Route path="/" element={<Login />} />
      {isDevEnv && <Route path="/exp" element={<ExpPage />} />}
      {isDevEnv && <Route path="/exp/:page" element={<ExpPage />} />}
      <Route path="/reset-password" element={<PasswordRecoveryRequest />} />
      <Route path="/reset-password/:token" element={<PasswordRecoverySetPassword />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/oauth2/callback/:provider" element={<OAuth2 />} />
      <Route path="*" element={<RedirectToLogin />} />
    </Routes>
  </React.StrictMode>
);

const InstanceLoader = observer((props) => {
  const { instanceName } = useParams();

  if (!store.Instances.loaded) {
    return <Spinner />;
  }

  useEffect(() => {
    store.Instances.setCurrentSelectedItem(instanceName);
    const instance = store.Instances.getByName(instanceName);
    if (instance && instance.token === null) {
      instance.getToken();
    }
  }, [instanceName]);

  const instance = store.Instances.getByName(instanceName);
  if (instance === undefined) {
    return <NotFound message={`Oops. We cannot find an instance with the name '${instanceName}'.`} />;
  }

  if (instance.token === null || instance.status === undefined || instance.status === null) {
    return <Spinner />;
  }

  if (instance.status !== InstanceStates.Ready) {
    return <InstanceNotReady key={instance.id} instance={instance} />;
  }

  if (instance.readinessState === InstanceReadinessStages.unknown) {
    return <Spinner />;
  }

  let component;
  if (instance.readinessState === InstanceReadinessStages.notHostApp) {
    component = <AgentNew />;
  } else if (instance.readinessState === InstanceReadinessStages.hostAppExists) {
    component = <NewHostPage applicationID={instance.Applications.filter(HOST_APP_TYPE)[0].id} />;
  } else {
    component = React.createElement(props.component, { instance: instance });
  }

  return (
    <Provider key={instanceName} instance={instance}>
      {component}
    </Provider>
  );
});

const LoggedView = observer(() => {
  useEffect(() => {
    if (!store.Instances.loaded && !store.Instances.loading) {
      store.Instances.fetch();
    }
  }, [store.Instances.loaded]);

  return (
    <PageBody>
      <React.StrictMode>
        <PopoversRootContainer />
        <div className="content">
          {!store.Instances.loaded ? (
            <Spinner />
          ) : (
            <Routes>
              <Route path="/" element={<Navigate to="/i/main/" />} />
              <Route path="/i/:instanceName/" element={<Navigate to="/i/main/dashboard" />} />
              <Route path="/i/:instanceName/dashboard" element={<InstanceLoader component={InstanceDashboard} />} />
              <Route path="/i/:instanceName/search" element={<InstanceLoader component={SearchResultsPage} />} />
              <Route path="/i/:instanceName/records/:recordId/*" element={<InstanceLoader component={RecordView} />} />
              <Route path="/i/:instanceName/hosts" element={<InstanceLoader component={HostsSearchPage} />} />
              <Route path="/i/:instanceName/hosts/register" element={<InstanceLoader component={NewHostPage} />} />
              <Route path="/i/:instanceName/containers" element={<InstanceLoader component={ContainersSearchPage} />} />
              <Route path="/i/:instanceName/scripts" element={<InstanceLoader component={ScriptsList} />} />
              <Route path="/i/:instanceName/scripts/create" element={<InstanceLoader component={ScriptCreatePage} />} />
              <Route path="/i/:instanceName/scripts/edit" element={<InstanceLoader component={ScriptEditPage} />} />
              <Route path="/i/:instanceName/ansible" element={<InstanceLoader component={Ansible} />} />
              <Route path="/i/:instanceName/instance" element={<InstanceLoader component={InstanceSettings} />} />
              <Route path="/i/:instanceName/users" element={<InstanceLoader component={UsersSearchPage} />} />
              <Route path="/i/:instanceName/api/*" element={<InstanceLoader component={Api} />} />
              <Route path="/i/:instanceName/agents" element={<InstanceLoader component={AgentsList} />} />
              <Route path="/i/:instanceName/agents/launch" element={<InstanceLoader component={AgentNew} />} />
              <Route path="/catalog/models" element={<ModelsList />} />
              <Route path="/catalog/models/:modelOrg:::modelGroup/:modelIdentifier/*" component={ModelPage} />
              <Route path="/organization/users/create" element={<UserCreate />} />
              <Route path="/organization/users/invite" element={<UserInvite />} />
              <Route path="/organization/*" element={<OrganizationViews />} />
              <Route path="/profile" element={<UserProfile />} />
              <Route path="/signup" element={<Welcome />} />
              <Route element={<NotFound />} />
            </Routes>
          )}
        </div>
        <div className="rightbar">
          <Routes>
            <Route path="/organization/users" component={RightSideBarWithUserInfo} />
          </Routes>
        </div>
      </React.StrictMode>
    </PageBody>
  );
});

@observer
class App extends React.Component {
  render() {
    let view;
    if (store.Profile.token) {
      view = <LoggedView key={store.Profile.token} />;
    } else if (store.Profile.isLogged && store.Profile.loading) {
      view = <div className="loader" />;
    } else {
      view = <AnonymousView />;
    }

    return (
      <Provider
        store={store}
        profile={store.Profile}
        settings={store.Settings}
        org={store.Organization}
        notifications={store.Notifications}
      >
        <BrowserRouter>{view}</BrowserRouter>
      </Provider>
    );
  }
}

export default App;
