import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';

import moment from 'moment';

import { JustADot, AllowedStatuses } from 'components/charts/StatusChart';
import JournalLogs from 'components/JournalLogs';
import { RecordLink } from 'components/InventoryLinks';

const ProgressDots = () => {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((current) => (current + 1) % 4);
    }, 300);
    return () => clearInterval(interval);
  }, []);

  return (
    <span style={{ whiteSpace: 'break-spaces' }}>
      {'.'.repeat(counter)}
      {' '.repeat(3 - counter)}
    </span>
  );
};

const ScriptRunTab = observer((props) => {
  const [completed, setCompleted] = useState(Boolean(props.record.script_run_1.completedAt));

  useEffect(() => {
    if (completed) {
      return;
    }
    const interval = setInterval(() => {
      if (!props.record.loading) {
        props.record.update();
      }
      if (props.record.script_run_1.completedAt) {
        setCompleted(true);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [completed]);

  let state;
  if (!completed) {
    state = (
      <>
        Still running
        <ProgressDots />
      </>
    );
  } else if (props.record.script_run_1.exitCode !== 0) {
    state = (
      <>
        Failed
        <JustADot status={AllowedStatuses.Critical} />
      </>
    );
  } else {
    state = (
      <>
        Finished
        <JustADot status={AllowedStatuses.Ok} />
      </>
    );
  }

  const stats = {
    State: state,
    PID: props.record.script_run_1.pid || 'n/a',
    'Started at': moment(props.record.script_run_1.startedAt).format(),
  };
  if (completed) {
    const completedAt = moment(props.record.script_run_1.completedAt).format();
    stats['Execution time'] = (
      <span title={`Completed at: ${completedAt}`} key="exec-time-value">
        {(props.record.script_run_1.completedAt - props.record.script_run_1.startedAt) / 1000}s
      </span>
    );
    stats['Exit code'] = props.record.script_run_1.exitCode;
  }

  let targetKey = 'Target';
  if (props.record.script_run_1.targets.length > 1) {
    targetKey += 's';
  }
  stats[targetKey] = props.record.script_run_1.targets.map((r) => <RecordLink key={r} recordId={r} />);

  stats['Initiated by'] = <RecordLink recordId={props.record.script_run_1.user} />;

  return (
    <JournalLogs inherits="std::host/ScriptRunLog:1" owner={props.record.root_1.id} stats={stats} watch={!completed} />
  );
});

ScriptRunTab.suites = (record) => record.script_run_1;

export default ScriptRunTab;
